import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import { FormControl, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS, fr, de, it, es, nl, pt } from 'date-fns/locale'
import { useTranslation } from '@contexts/translation'
import { SelectSkeleton } from '@components/common/Skeletons'

const DatePicker = ({
  name,
  label,
  control,
  error,
  id,
  errorMessage,
  fullWidth,
  required,
  disabled,
  clearable,
  width,
  margin = 'normal',
  size = 'medium',
  textFieldSize = 'small',
  inputFormat = 'dd/MM/yyyy',
  defaultValue = null,
  loading = false,
  shouldDisableDate = () => null,
  customRenderInput = null,
  data_testid,
  InputProps = {},
  components,
  ...props
}) => {
  const [value, setValue] = useState(null)
  const { lang } = useTranslation()

  const localeMap = {
    en: { locale: enUS, placeholder: 'dd/mm/yyyy' },
    fr: { locale: fr, placeholder: 'jj/mm/aaaa' },
    de: { locale: de, placeholder: 'tt/mm/jjjj' },
    it: { locale: it, placeholder: 'gg/mm/aaaa' },
    es: { locale: es, placeholder: 'dd/mm/aaaa' },
    nl: { locale: nl, placeholder: 'dd/mm/jjjj' },
    pt: { locale: pt, placeholder: 'jj/mm/aaaa' }
  }

  return (
    <>
      {!loading && (
        <FormControl
          {...props}
          margin={margin}
          size={size}
          style={{ width: fullWidth ? '100%' : width || '195px' }}
        >
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[lang]?.locale}
              >
                <DesktopDatePicker
                  views={['year', 'month', 'day']}
                  value={value}
                  disabled={disabled}
                  inputFormat={inputFormat}
                  clearable={clearable}
                  onChange={newValue => {
                    setValue(newValue)
                  }}
                  shouldDisableDate={shouldDisableDate}
                  InputProps={InputProps}
                  {...(components && { components })}
                  {...field}
                  renderInput={
                    customRenderInput
                      ? customRenderInput
                      : params => (
                          <TextField
                            {...params}
                            data-testid={data_testid}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: localeMap[lang]?.placeholder
                            }}
                            label={label}
                            id={id}
                            disabled={disabled}
                            name={name}
                            error={error}
                            helperText={errorMessage}
                            required={required}
                            fullWidth
                            size={textFieldSize}
                            style={{
                              backgroundColor: '#fff'
                            }}
                            // sx={{
                            //   '& .MuiFilledInput-input': {
                            //     paddingTop: label ? 2 : 1.5,
                            //     paddingBottom: label ? 1 : 1.5
                            //   }
                            // }}
                          />
                        )
                  }
                  minDate={new Date(1970, 0, 1)}
                  maxDate={new Date(2100, 11, 31)}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      )}
      {loading && <SelectSkeleton />}
    </>
  )
}

export default DatePicker
