import { Controller } from 'react-hook-form'
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import {
  SelectMultilineSkeleton,
  SelectSkeleton
} from '@components/common/Skeletons'
import { inputLabelClasses } from '@mui/material/InputLabel'

// import { ErrorOutline as ErrorIcon } from '@mui/icons-material'

export const TextFieldItemController = ({
  errorMessage,
  fullWidth = true,
  control,
  name,
  defaultValue,
  label,
  ...props
}) => {
  const isError = Boolean(errorMessage)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl fullWidth={fullWidth} margin="dense">
          <TextField
            variant="outlined"
            size="small"
            label={label}
            error={isError}
            helperText={isError && errorMessage}
            {...props}
            {...field}
          />
        </FormControl>
      )}
    />
  )
}

const TextFieldItem = ({
  field,
  errorMessage,
  data_testid,
  fullWidth = true,
  focused = false,
  required = false,
  endAdornmentContent = null,
  endAdornmentAction = '',
  endAdornmentClick = () => null,
  selectOnFocus = false,
  loading = false,
  ...props
}) => {
  delete props.fieldState
  delete props.formState
  const isError = Boolean(errorMessage)
  return (
    <>
      {!loading && (
        <FormControl fullWidth={fullWidth} margin="dense">
          <TextField
            data-testid={data_testid}
            autoFocus={focused}
            required={required}
            error={isError}
            helperText={
              isError && (
                <span style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</span>
              )
            }
            // InputProps={{
            //   endAdornment: (
            //     // Take a look to theme .MuiTextField + .MuiInputAdornment (position: absolute + relative)
            //     <InputAdornment position="end">
            //       <IconButton disableRipple>
            //         {isError && <ErrorIcon color="error" />}
            //       </IconButton>
            //     </InputAdornment>
            //   )
            // }}
            InputProps={{
              ...(endAdornmentContent && {
                endAdornment: (
                  <InputAdornment position="end">
                    {endAdornmentAction ? (
                      <IconButton
                        edge="end"
                        type={endAdornmentAction}
                        onClick={endAdornmentClick}
                        data-testid="IconButton-70fa92cd-9ddd-4806-8706-1a8b4b2eeda0"
                      >
                        {endAdornmentContent}
                      </IconButton>
                    ) : (
                      endAdornmentContent
                    )}
                  </InputAdornment>
                )
              })
              // ...{
              //   sx: {
              //     height: '44px',
              //     paddingBottom: '15px',
              //     '&:focus-within fieldset, &:focus-visible fieldset': {
              //       border: '2px solid #7966eb!important'
              //     }
              //   }
              // }
            }}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: '#7966eb!important'
                }
              }
            }}
            onFocus={event => (selectOnFocus ? event.target.select() : null)}
            {...field}
            {...props}
            maxRows={3}
          />
        </FormControl>
      )}

      {loading && props.multiline && <SelectMultilineSkeleton />}
      {loading && !props.multiline && <SelectSkeleton />}
    </>
  )
}

export default TextFieldItem
