import { useEffect, useState } from 'react'
import {
  Popover,
  Button,
  Box,
  Typography,
  Checkbox,
  Tooltip,
  Stack,
  Divider,
  CircularProgress
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import { SelectSkeleton } from '@components/common/Skeletons'

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Controller, useForm } from 'react-hook-form'

import TextFieldItem from '@components/common/Form/TextFieldItem'
import Select from '@components/common/Select'
import DatePicker from '@components/form/DatePicker'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { useTranslation } from '@contexts/translation'
import AutocompleteItem from '../Autocomplete'

const CommonPopoverForm = ({
  buttonLabel,
  propertyLabel,
  customTitle = null,
  type = 'text',
  defaultValue,
  valueNeedsUpdate = null,
  style = {},
  className,
  checkboxes = [],
  isMultiline = false,
  tooltipText = '',
  onSubmit = () => null,
  children,
  multiple = false,
  clearable = false,
  submitType = 'submit',
  disabled = false,
  variant = 'subtitle2',
  data_testid = '',
  inputProps = {},
  onClick = () => null,
  noWrap = false,
  loadingData = false,
  noLoader = false,
  loaderAlignRight = false,
  users
}) => {
  const theme = useTheme()
  const { dictionary } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [currentCheckboxes, setCurrentCheckboxes] = useState(defaultValue)
  const [color, setColor] = useState(defaultValue)

  const getDefaultValues = () => {
    if (type === 'select') {
      return { selectPopover: defaultValue }
    } else if (type === 'calendar') {
      return { calendarPopover: defaultValue }
    } else if (type === 'checkbox') {
      return { checkboxesPopover: defaultValue }
    } else if (type === 'colorPicker') {
      return { colorPickerPopover: defaultValue }
    } else {
      return { textPopover: defaultValue }
    }
  }

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
    defaultValues: getDefaultValues()
  })

  // Project/Fees/[projec_id]/[feeproject_id]: Force valid or under review
  useEffect(() => {
    if (valueNeedsUpdate) {
      setValue(valueNeedsUpdate.type, valueNeedsUpdate.param)
    }
  }, [valueNeedsUpdate])

  useEffect(() => {
    if (
      type !== 'select' &&
      type !== 'calendar' &&
      type !== 'checkbox' &&
      type !== 'colorPicker'
    )
      setValue('textPopover', defaultValue)
  }, [defaultValue])

  const _onSubmit = async (values, closer) => {
    closer.close()
    setLoading(true)
    if (type === 'colorPicker') values = { colorPickerPopover: color }
    onSubmit &&
      (await onSubmit(type === 'checkbox' ? currentCheckboxes : values))
    setLoading(false)
  }

  const handleChangeCheckboxes = checkboxId => {
    const copyCurrentCheckboxes = [...currentCheckboxes]
    const isItemWasChecked = copyCurrentCheckboxes.includes(
      checkboxes[checkboxId]
    )

    if (isItemWasChecked) {
      const indexOfChecked = copyCurrentCheckboxes.indexOf(
        checkboxes[checkboxId]
      )

      copyCurrentCheckboxes.splice(indexOfChecked, 1)
    } else {
      copyCurrentCheckboxes.push(checkboxes[checkboxId])
    }

    setCurrentCheckboxes(copyCurrentCheckboxes)
  }

  const getFieldByType = () => {
    if (type === 'select') {
      return propertyLabel !== dictionary.assigned_to ? (
        <Select
          fullWidth
          name="selectPopover"
          label={customTitle ? customTitle : propertyLabel}
          control={control}
          defaultValue={defaultValue}
          multiple={multiple}
          data_testid="Select-a9342b43-fbca-42a6-ba5f-9449c3960dd5"
        >
          {children}
        </Select>
      ) : (
        <AutocompleteItem
          multiple={multiple}
          name="selectPopover"
          label={customTitle ? customTitle : propertyLabel}
          control={control}
          defaultValue={[]}
          elements={{
            items: users || [],
            id: 'id',
            display: 'display'
          }}
        />
      )
    } else if (type === 'calendar') {
      return (
        <DatePicker
          name="calendarPopover"
          label={propertyLabel}
          variant="outlined"
          defaultValue={null}
          fullWidth
          control={control}
          error={errors.calendarPopover}
          errorMessage={
            errors.calendarPopover && errors.calendarPopover.message
          }
          clearable={clearable}
          data_testid="DatePicker-771cebd8-b779-409d-820d-f85e4b05b66b"
        />
      )
    } else if (type === 'checkbox') {
      return (
        <Box>
          {checkboxes.map((checkbox, checkboxId) => (
            <Controller
              key={checkboxId}
              name="checkboxesPopover"
              control={control}
              render={props => (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    {...props}
                    checked={currentCheckboxes.includes(checkbox)}
                    onChange={() => handleChangeCheckboxes(checkboxId)}
                    data-testid="Checkbox-ea431a77-f74f-4801-a563-053c6a1c272d"
                  />

                  <Typography
                    padding="5px"
                    data-testid="Typography-f8150868-b866-46bb-a4b2-33a61ae78882"
                  >
                    {checkbox}
                  </Typography>
                </Box>
              )}
            />
          ))}
        </Box>
      )
    } else if (type === 'colorPicker') {
      return (
        <>
          <HexColorPicker color={color} onChange={setColor} />
          <HexColorInput color={color} onChange={setColor} />
        </>
      )
    } else {
      return (
        <Controller
          name="textPopover"
          control={control}
          render={props => (
            <TextFieldItem
              type={type}
              autoFocus
              onFocus={e => e.target.select()}
              multiline={isMultiline}
              placeholder={propertyLabel}
              label={propertyLabel}
              errorMessage={errors.textPopover?.message}
              InputProps={{ inputProps }}
              {...props}
            />
          )}
        />
      )
    }
  }

  if (disabled) {
    return (
      <div
        data-testid={data_testid}
        style={{
          width: 'fit-content',
          marginLeft: 'auto',
          ...style
        }}
        className={className}
      >
        <Tooltip title={tooltipText || ''}>
          <Stack>{buttonLabel}</Stack>
        </Tooltip>
      </div>
    )
  }

  return (
    <div
      data-testid={data_testid}
      style={{
        width: 'fit-content',
        cursor: 'pointer',
        marginLeft: 'auto',
        ...style
      }}
      className={className}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') onClick()
      }}
    >
      <PopupState variant="popover" popupId="popup-popover-form">
        {popupState => (
          <div>
            <Tooltip title={tooltipText || ''}>
              <Stack
                {...bindTrigger(popupState)}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                {!noLoader && loading && !loaderAlignRight && (
                  <CircularProgress size={10} />
                )}
                <Typography
                  variant={variant}
                  noWrap={noWrap}
                  data-testid="Typography-b3d84fed-8683-4db9-bda6-fa944af84ad7"
                >
                  {buttonLabel}
                </Typography>
                {!noLoader && loading && loaderAlignRight && (
                  <CircularProgress size={10} />
                )}
              </Stack>
            </Tooltip>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
            >
              <form
                data-cy={'form'}
                noValidate
                onSubmit={handleSubmit(values => _onSubmit(values, popupState))}
                style={{ width: theme.spacing(40) }}
              >
                <Stack p={2}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    data-testid="Typography-527373c1-0e12-40be-84bd-9124a6afeaf4"
                  >
                    {customTitle ? customTitle : propertyLabel}
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Stack m={3}>
                    {loadingData && <SelectSkeleton />}
                    {!loadingData && getFieldByType()}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                    mx={3}
                    mb={3}
                  >
                    <Button
                      type={submitType}
                      onClick={
                        submitType === 'submit'
                          ? null
                          : handleSubmit(values => {
                              if (type === 'colorPicker')
                                values = { colorPickerPopover: color }
                              onSubmit(values)
                              popupState.close()
                            })
                      }
                      variant="outlined"
                      disabled={loading}
                      data-testid="Button-00e66b51-5c6d-43b9-976c-18721b4e935d"
                    >
                      <CheckIcon />
                    </Button>
                    <Button
                      type="reset"
                      variant="outlined"
                      color="error"
                      onClick={popupState.close}
                      data-testid="Button-74918328-1255-46c5-b57c-3d5d41e145a6"
                    >
                      <CloseIcon />
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  )
}

export default CommonPopoverForm
