import { Controller } from 'react-hook-form'
import {
  FormHelperText,
  FormControl,
  Checkbox,
  Autocomplete,
  Typography,
  Chip,
  Stack,
  Tooltip
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
import TextFieldItem from './Form/TextFieldItem'
import { SelectSkeleton } from './Skeletons'
import useChipStyles from '@styles/chip/chip'
import { useCallback, useMemo } from 'react'
import { Add } from '@mui/icons-material'

// export type OptionType = {
//   [key: string]: any
//   disabled?: boolean
//   tooltip?: string
//   hidden?: boolean
//   highlight?: boolean
//   icon?: ReactNode
//   subheader?: boolean
// }

// type AutocompleteProps = {
//   name: string
//   label: string
//   control: Control<any, any>
//   defaultValue?: string | number | string[] | number[]
//   multiple?: boolean
//   error?: boolean
//   errorMessage?: string
//   helperText?: string | ReactNode
//   elements: {
//     items: OptionType[]
//     id?: string
//     display?: string
//     disabled?: string
//   } // { items: array, id: 'id field' (can be 'id', 'pk'...), display: 'display field' (can be 'name', 'display', 'title'...) }
//   showCount?: boolean
//   freeSolo?: boolean
//   loading?: boolean
//   skeletonLoading?: boolean
//   variant?: 'filled' | 'outlined' | 'standard'
//   submitOnClick?: (value: any | any[]) => void
//   groupBy?: (option: any) => string
//   required?: boolean
//   disabled?: boolean
//   helpText?: string
//   onClickOption?: (e: any, option: any) => void
//   chipTags?: boolean
//   disableClearable?: boolean
//   addOption?: string
//   handleAdd?: () => void
//   disableListWrap?: boolean
//   data_testid?: string
//   disableSelectAll?: boolean
//   onClear?: () => void
//   // className?: string
//   size?: 'small' | 'medium'
// }

const AutocompleteItem = ({
  name,
  label,
  control,
  defaultValue,
  multiple = false,
  errorMessage,
  helperText,
  elements = { items: [], id: '', display: '', disabled: '' },
  showCount = false,
  freeSolo = false,
  loading = false,
  skeletonLoading = false,
  variant = 'filled',
  submitOnClick,
  groupBy,
  required,
  disabled = false,
  helpText = false,
  onClickOption = () => null,
  chipTags,
  disableClearable = false,
  addOption,
  handleAdd,
  disableListWrap = false,
  data_testid,
  disableSelectAll = false,
  onClear,
  // className,
  size = 'medium',
  ...props
}) => {
  const { t } = useTranslation()
  const labelId = `${name}-label`
  const classesChip = useChipStyles()

  const idField = elements?.id || 'id'
  const displayField = elements?.display || 'display'

  const options = useMemo(() => {
    const preOptions = []
    if (addOption && handleAdd)
      preOptions.push({
        [idField]: 'add_item',
        [displayField]: addOption
      })
    if (multiple)
      preOptions.push({
        [idField]: 'all',
        [displayField]: t('chip_filter_selector_select_all'),
        hidden: disableSelectAll
      })
    if (elements?.items) {
      return [...preOptions, ...elements.items]
    }
    return []
  }, [
    elements,
    addOption,
    multiple,
    disableSelectAll,
    idField,
    displayField,
    t,
    handleAdd
  ])

  const getOptionLabel = useCallback(
    option => {
      if (option[displayField]) {
        return option[displayField]
      }
      const foundElement = elements.items.find(el => el[idField] == option)
      return foundElement ? foundElement[displayField] : ''
    },
    [elements, displayField, idField]
  )

  const renderOption = useCallback(
    (field, props, option, state) => {
      const { selected } = state

      const isSelectAllOption = option[idField] === 'all'
      const isAddItemOption = option[idField] === 'add_item'

      // @ts-expect-error - key is indeed in props
      delete props.key // to avoid console error

      return !option.hidden ? (
        <Tooltip title={option.tooltip || ''} arrow key={props.id}>
          <div>
            {option.subheader ? (
              <li tabIndex={0} style={{ padding: '6px' }}>
                <Typography>
                  <b>{option[displayField]}</b>
                </Typography>
              </li>
            ) : (
              <li
                tabIndex={0}
                role="button"
                {...props}
                onClick={e => {
                  onClickOption(e, option)
                  // @ts-expect-error - onClick is in props and cannot be undefined with Autocomplete
                  props.onClick(e)
                }}
                data-testid="li-autocomplete-96yd609g44"
              >
                {multiple && !isAddItemOption && (
                  <Checkbox
                    sx={{ mr: 1 }}
                    checked={
                      isSelectAllOption
                        ? field.value.length === elements.items.length
                        : selected
                    }
                    indeterminate={
                      isSelectAllOption
                        ? field.value.length < elements.items.length &&
                          field.value.length > 0
                        : false
                    }
                    data-testid="Checkbox-e6e3c4f1-609c-40fc-ae57-4398d68e4232"
                  />
                )}

                {isAddItemOption ? (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Add color="primary" />
                    <Typography color="primary">
                      {option[displayField]}
                    </Typography>
                  </Stack>
                ) : multiple && isSelectAllOption ? (
                  <b>{option[displayField]}</b>
                ) : (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {option.icon}

                    <Typography
                      color={option.highlight ? 'primary' : undefined}
                    >
                      {option[displayField]}
                    </Typography>
                  </Stack>
                )}
              </li>
            )}
          </div>
        </Tooltip>
      ) : undefined
    },
    [elements, idField, displayField, onClickOption, multiple]
  )

  const renderMultipleTagsWithoutChip = useCallback(
    (value, element, multipleElements) => {
      return multipleElements ? (
        showCount ? (
          <Typography noWrap variant="subtitle2" color="text.dark">
            {element?.[displayField] +
              (value.length - 1 > 0 ? ` +${value.length - 1}` : '')}
          </Typography>
        ) : (
          <Typography noWrap variant="subtitle2" color="text.dark">
            {multipleElements.join(', ')}
          </Typography>
        )
      ) : null
    },
    [showCount, displayField]
  )

  const renderMultipleTagsWithChip = useCallback(
    multipleElements => {
      if (multipleElements && multipleElements.length > 2) {
        return (
          <>
            {multipleElements.slice(0, 2).map((e, index) => (
              <Chip
                key={index}
                label={e}
                size="small"
                className={classesChip.normal}
                sx={{
                  marginRight: 1,
                  paddingY: 2,
                  marginBottom: 1
                }}
              />
            ))}
            <Chip
              label={`+${multipleElements.slice(2).length}`}
              size="small"
              className={classesChip.normal}
              sx={{
                marginRight: 1,
                paddingY: 2,
                marginBottom: 1
              }}
            />
          </>
        )
      } else {
        return multipleElements?.map((e, index) => (
          <Chip
            key={index}
            label={e}
            size="small"
            className={classesChip.normal}
            sx={{
              marginRight: 1,
              paddingY: 2,
              marginBottom: 1
            }}
          />
        ))
      }
    },
    [classesChip]
  )

  const renderTags = useCallback(
    value => {
      const element = elements.items.find(el =>
        multiple ? value.includes(el[idField]) : el[idField] == value
      )
      const multipleElements = multiple
        ? elements.items
            .filter(el => value.includes(el[idField]))
            .map(el => el[displayField])
        : undefined
      return multiple
        ? chipTags
          ? renderMultipleTagsWithChip(multipleElements)
          : renderMultipleTagsWithoutChip(value, element, multipleElements)
        : elements.items.find(el => el[idField] == value)?.[displayField]
    },
    [
      elements,
      multiple,
      chipTags,
      idField,
      displayField,
      renderMultipleTagsWithChip,
      renderMultipleTagsWithoutChip
    ]
  )

  return (
    <FormControl {...props} variant="filled" margin="normal" fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <>
              {!skeletonLoading && (
                <Autocomplete
                  disableClearable={disableClearable}
                  fullWidth
                  size={size}
                  data-testid={data_testid}
                  id={labelId}
                  multiple={multiple}
                  disabled={disabled}
                  disableCloseOnSelect={multiple}
                  loading={loading}
                  groupBy={groupBy}
                  getOptionDisabled={option => {
                    if (
                      (elements?.disabled && option[elements.disabled]) ||
                      option.disabled
                    ) {
                      return true
                    }
                    return false
                  }}
                  value={
                    (freeSolo
                      ? elements.items.find(
                          item => item[idField] === field.value
                        )
                      : field.value) || null
                  }
                  // value={(freeSolo ? defaultValue : field.value) || null}
                  onInputChange={(event, value, reason) => {
                    if (freeSolo && value) field.onChange(value)
                    else if (reason === 'clear' && onClear) onClear()
                  }}
                  onChange={(event, newValue) => {
                    let _newValue

                    if (
                      !multiple &&
                      newValue?.[idField] === 'add_item' &&
                      handleAdd
                    ) {
                      handleAdd()
                      return
                    }

                    if (multiple) {
                      if (
                        newValue?.map(v => v[idField]).includes('add_item') &&
                        handleAdd
                      ) {
                        handleAdd()
                        return
                      }
                      if (
                        newValue?.[newValue.length - 1]?.[idField] === 'all' ||
                        newValue?.[newValue.length - 1] === 'all'
                      ) {
                        _newValue =
                          field.value.length === elements.items.length
                            ? []
                            : elements.items.map(v => v[idField])
                        field.onChange(_newValue)
                      } else {
                        _newValue = newValue.map(v =>
                          v?.[idField] ? v[idField] : v
                        )

                        field.onChange(_newValue)
                      }
                    } else {
                      _newValue = newValue?.[idField] || ''
                      field.onChange(newValue?.[idField] || '')
                    }
                    if (submitOnClick) submitOnClick(_newValue)
                  }}
                  freeSolo={freeSolo}
                  isOptionEqualToValue={(option, value) => {
                    return option[idField].toString() === value.toString()
                  }}
                  options={options}
                  // options={
                  //   elements.items
                  //     ? multiple
                  //       ? [
                  //           {
                  //             [idField]: 'all',
                  //             [displayField]: dictionary.select_all
                  //           },
                  //           ...elements.items
                  //         ]
                  //       : elements.items
                  //     : []
                  // }
                  getOptionLabel={getOptionLabel}
                  // getOptionLabel={option => {
                  //   if (option[displayField]) return option[displayField] || ''
                  //   return (
                  //     elements.items.find(el => el[idField] == option)?.[
                  //       displayField
                  //     ] || ''
                  //   )
                  // }}
                  renderOption={(props, option, state) =>
                    renderOption(field, props, option, state)
                  }
                  // renderOption={(props, option, { selected }) => {
                  //   return (
                  //     <li
                  //       {...props}
                  //       key={props.id}
                  //       onClick={e => {
                  //         onClickOption(e, option)
                  //         props.onClick(e)
                  //       }}
                  //       data-testid="li-autocomplete-96yd609g44"
                  //     >
                  //       {multiple && (
                  //         <Checkbox
                  //           sx={{ mr: 1 }}
                  //           checked={
                  //             option[idField] === 'all'
                  //               ? field.value.length === elements.items.length
                  //               : selected
                  //           }
                  //           indeterminate={
                  //             option[idField] === 'all'
                  //               ? field.value.length < elements.items.length &&
                  //                 field.value.length > 0
                  //               : false
                  //           }
                  //           data-testid="Checkbox-e6e3c4f1-609c-40fc-ae57-4398d68e4232"
                  //         />
                  //       )}

                  //       {multiple &&
                  //       option[displayField] === dictionary.select_all ? (
                  //         <b>{option[displayField]}</b>
                  //       ) : (
                  //         option[displayField]
                  //       )}
                  //     </li>
                  //   )
                  // }}
                  // {...(showCount && { limitTags: 1 })}
                  renderTags={value => renderTags(value)}
                  // rendderTags={renderTags}
                  // renderTags={value => {
                  //   const element = elements.items.find(el =>
                  //     multiple
                  //       ? value.includes(el[idField])
                  //       : el[idField] == value
                  //   )
                  //   const multipleElements = multiple
                  //     ? elements.items
                  //         .filter(el => value.includes(el[idField]))
                  //         .map(el => el[displayField])
                  //     : undefined
                  //   return multiple ? (
                  //     chipTags ? (
                  //       multipleElements.length > 2 ? (
                  //         <>
                  //           {multipleElements.slice(0, 2).map((e, index) => (
                  //             <Chip
                  //               key={index}
                  //               label={e}
                  //               size="small"
                  //               className={classesChip.normal}
                  //               sx={{
                  //                 marginRight: 1,
                  //                 paddingY: 2,
                  //                 marginBottom: 1
                  //               }}
                  //             />
                  //           ))}
                  //           <Chip
                  //             label={`+${multipleElements.slice(2).length}`}
                  //             size="small"
                  //             className={classesChip.normal}
                  //             sx={{
                  //               marginRight: 1,
                  //               paddingY: 2,
                  //               marginBottom: 1
                  //             }}
                  //           />
                  //         </>
                  //       ) : (
                  //         multipleElements.map((e, index) => (
                  //           <Chip
                  //             key={index}
                  //             label={e}
                  //             size="small"
                  //             className={classesChip.normal}
                  //             sx={{
                  //               marginRight: 1,
                  //               paddingY: 2,
                  //               marginBottom: 1
                  //             }}
                  //           />
                  //         ))
                  //       )
                  //     ) : showCount ? (
                  //       element?.[displayField] +
                  //       (value.length - 1 > 0 ? ` +${value.length - 1}` : '')
                  //     ) : (
                  //       <Typography noWrap>
                  //         {multipleElements.join(', ')}
                  //       </Typography>
                  //     )
                  //   ) : (
                  //     elements.items.find(el => el[idField] == value)?.[
                  //       displayField
                  //     ]
                  //   )
                  // }}
                  renderInput={params => (
                    <TextFieldItem
                      {...params}
                      label={label}
                      variant={variant}
                      required={required}
                      data-testid="TextFieldItem-875804cf-c362-4132-8c2e-cd192ef3a934"
                    />
                  )}
                  disableListWrap={disableListWrap}
                  sx={{
                    ...(disableListWrap && {
                      '& .MuiAutocomplete-inputRoot': {
                        whiteSpace: 'nowrap',
                        flexWrap: 'nowrap'
                      }
                    })
                  }}
                />
              )}

              {skeletonLoading && <SelectSkeleton size={size} />}
            </>
          )
        }}
      />
      {helpText && <Typography sx={{ marginTop: 1 }}>{helpText}</Typography>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default AutocompleteItem
